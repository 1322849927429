* {
  box-sizing: border-box;
  margin: 0;
}

/*General Font*/
@font-face{
  font-family:'IBM Plex Sans Condensed', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed&display=swap') format('truetype');
}

@font-face{
  font-family: 'Work Sans';
  src: url('https://fonts.googleapis.com/css2?family=Rubik+Iso&display=swap');
}

/*Preset Colours*/
:root{
  --comp-col-01:#222323;
  --comp-col-02:#f0f6f0;
  --comp-col-03:#b5e877;
  --comp-col-04:#5252ff;
  --comp-col-cycle:#5252ff;
  --comp-hoverlay:linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.2));
  font-size: 2vmin;
  --font-var-one: 0;
  --font-var-two: 0;
  --font-var-three: 0;
}

::after{
  box-sizing: border-box;
}

html, #root, body{
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#global-wrapper{
  width: 100%;
  height: 100%;
  background-color: var(--comp-col-01);
}

/* Door Closed - Text Logo */
#logo-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
#text-logo{
  color: var(--comp-col-02);
  padding: 1vw auto;
}
#pre-logo-wrapper{
  font-size: 2vmin;
  margin: 0;
  white-space: pre-wrap;
}
.text-gradient-1{ color: #b5e877; }
.text-gradient-2{ color: #65e285; }
.text-gradient-3{ color: #00daa1; }
.text-gradient-4{ color: #00cec7; }
.text-gradient-5{ color: #00c0ee; }
.text-gradient-6{ color: #00afff; }
.text-gradient-7{ color: #0099ff; }
.text-gradient-8{ color: #007dff; }
.text-gradient-9{ color: #5252ff; }
/* Text Logo Animations */
#logo-line-1{ animation: 0.4s ease-out 1 slideIn1; }
@keyframes slideIn1 { 0% { transform: translateX(-1%); } 100% { transform: translateX(0); } }
#logo-line-2{ animation: 0.4s ease-out 1 slideIn2; }
@keyframes slideIn2 { 0% { transform: translateX(0.5%); } 100% { transform: translateX(0); } }
#logo-line-3{ animation: 0.4s ease-out 1 slideIn3; }
@keyframes slideIn3 { 0% { transform: translateX(-2%); } 100% { transform: translateX(0); } }
#logo-line-4{ animation: 0.4s ease-out 1 slideIn4; }
@keyframes slideIn4 { 0% { transform: translateX(0.75%); } 100% { transform: translateX(0); } }
#logo-line-5{ animation: 0.4s ease-out 1 slideIn5; }
@keyframes slideIn5 { 0% { transform: translateX(-0.25%); } 100% { transform: translateX(0); } }
#logo-line-6{ animation: 0.4s ease-out 1 slideIn6; }
@keyframes slideIn6 { 0% { transform: translateX(0.5%); } 100% { transform: translateX(0); } }
#logo-line-7{ animation: 0.4s ease-out 1 slideIn7; }
@keyframes slideIn7 { 0% { transform: translateX(-1%); } 100% { transform: translateX(0); } }
#logo-line-8{ animation: 0.4s ease-out 1 slideIn8; }
@keyframes slideIn8 { 0% { transform: translateX(1%); } 100% { transform: translateX(0); } }
#logo-line-9{ animation: 0.4s ease-out 1 slideIn9; }
@keyframes slideIn9 { 0% { transform: translateX(-0.25%); } 100% { transform: translateX(0); } }




